import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-testmonial',
  templateUrl: './testmonial.component.html',
  styleUrls: ['./testmonial.component.css']
})
export class TestmonialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
